import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { Section } from "react-inclusive-components";

const backgrounds = ["papayawhip", "palegreen", "palevioletred"];

const color = backgrounds[Math.floor(Math.random() * backgrounds.length)];

const Grid = styled(Section)`
  height: ${props => `${100 * props.vh}px`};
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0px, max-content));
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: ${color};
  font-family: "Helvetica Neue", Helvetica, sans-serif;

  h1 {
    margin: 16px;
    grid-row: 3;
    grid-column: 2/3;
    font-weight: 200;
    color: #000;
  }
`;

const GlobalContext = React.createContext({});

const Container = props => {
  const initialState = {
    name: "",
    reason: "",
    success: false,
    error: false,
    sending: false,
    loading: true,
    names: []
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "NAMESFETCHING":
        return { ...state, error: false, loading: true };
      case "KUDOSSENDING":
        return { ...state, error: false, sending: true };
      case "NAMESFETCHED":
        return {
          ...state,
          error: false,
          names: action.payload,
          loading: false
        };
      case "NAMECHANGE":
        return { ...state, error: false, name: action.payload };
      case "REASONCHANGE":
        return { ...state, error: false, reason: action.payload };
      case "ERROR":
        return { ...state, error: true };
      case "ERRORLOADING":
        return { ...state, error: true, success: false };
      case "SUCCESS":
        return { ...state, error: false, success: true, sending: false };
      case "RESET":
        return { ...initialState, names: state.names, loading: false };
      default:
        return { ...state };
    }
  };

  let [state, dispatch] = useReducer(reducer, initialState);

  let [vh, setVh] = useState(window.innerHeight / 100);

  let globalReducer = { state, dispatch };

  const handleResize = () => {
    setVh(window.innerHeight / 100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GlobalContext.Provider value={globalReducer}>
      <Grid
        {...props}
        noHeading={!state.names.length || state.error || state.success}
        vh={vh}
      />
    </GlobalContext.Provider>
  );
};

export { Container, GlobalContext };
