import styled from "styled-components";
import { Button } from "react-inclusive-components";

const KudosButton = styled(Button)`
  display: block;
  font-size: 1em;
  font-weight: 200;
  background-color: #fccb7e;
  color: #000;
  margin: 32px auto 8px;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #fccb7e;
  box-shadow: 2px 2px #dfbc73;

  &:active {
    border: 1px solid #dfbc73;
    box-shadow: 3px 2px #dfbc73 inset;
  }
`;

export default KudosButton;
