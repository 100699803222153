import React from "react";
import "./App.css";
import { Container } from "./components/container";
import Form from "./components/Form/form";
import InteractiveKawaii from "./components/kawaii";

const App = () => {
  return (
    <Container
      labelId="kudos-heading"
      label="Give a gift of Kudos to somebody!"
    >
      <InteractiveKawaii size={150} />
      <Form />
    </Container>
  );
};

export default App;
