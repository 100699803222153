import styled from "styled-components";
import { List } from "react-inclusive-components";

const NamesList = styled(List)`
  background-color: transparent;
  text-align: left;
  max-width: 300px;
  max-height: 150px;
  overflow: scroll;
  padding: 0;
  margin: 0 auto;
  transform: translateY(8px);

  &:empty {
    & + label {
      display: block !important;

      & + input {
        display: inline-block !important;

        & + button {
          display: block !important;
        }
      }
    }
  }

  & + label {
    display: none !important;

    & + input {
      display: none !important;

      & + button {
        display: none;
      }
    }
  }

  li {
    padding: 5px 20px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 200;
    list-style-type: none;

    &:hover {
      background-color: transparent;
    }
  }
`;

export default NamesList;
