import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import KudosInput from "./input";
import KudosButton from "./button";
import ResultMessage from "../resultMessage";
import NamesList from "../namesList";
import { GlobalContext } from "../container";

const FormView = styled.form`
  label {
    color: #000;
    font-size: 1rem;
    font-weight: 200;
    display: block;
    margin: 8px auto;
  }
  grid-column: 2/3;
  grid-row: 4;
`;

const Form = props => {
  const { state, dispatch } = useContext(GlobalContext);

  const changeName = ({ target }) =>
    dispatch({ type: "NAMECHANGE", payload: target.value });

  const setName = name => dispatch({ type: "NAMECHANGE", payload: name });

  const changeReason = ({ target }) =>
    dispatch({ type: "REASONCHANGE", payload: target.value });

  const reset = () => {
    if (state.error && !state.names) {
      document.location.reload();
    }
    dispatch({ type: "RESET" });
  };

  const submitKudos = e => {
    const { name, names, reason } = state;
    e.preventDefault();
    if (names.indexOf(name) < 0) {
      dispatch({ type: "ERROR" });
      return;
    }
    dispatch({ type: "KUDOSSENDING" });
    axios.post("/sendkudos", { name, reason }).then(() => {
      dispatch({ type: "SUCCESS" });
    });
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" || e.key === " ") {
      setName(e.target.innerText);
    }
  };

  const filterNames = (item, index) => {
    const comparableItem = item.toLowerCase();
    const comparableStateName = state.name.toLowerCase();

    if (
      comparableItem.indexOf(comparableStateName) >= 0 &&
      comparableItem !== comparableStateName
    )
      return (
        <li
          tabIndex="0"
          onKeyPress={handleKeyPress}
          onClick={() => setName(item)}
          key={`${item}-${index}`}
        >
          {item}
        </li>
      );
  };

  useEffect(() => {
    dispatch({ type: "NAMESFETCHING" });
    axios
      .get("/names")
      .then(result => {
        if (result.data.error) {
          dispatch({ type: "ERRORLOADING" });
          return;
        }
        dispatch({ type: "NAMESFETCHED", payload: result.data });
      })
      .catch(e => {
        dispatch({ type: "ERRORLOADING" });
      });
  }, []);

  return (
    <React.Fragment>
      {!state.success && !state.error && !state.loading && !state.sending && (
        <FormView onSubmit={submitKudos}>
          <KudosInput
            required
            id="kudos-name"
            label="Name"
            value={state.name}
            onChange={changeName}
          />
          {state.names && state.name && (
            <NamesList>{state.names.map(filterNames)}</NamesList>
          )}
          <KudosInput
            id="kudos-reason"
            label="Reason"
            value={state.reason}
            onChange={changeReason}
          />
          <KudosButton type="submit">Give Kudos!</KudosButton>
        </FormView>
      )}
      {state.success && (
        <ResultMessage>
          <h2>Success!</h2>
          <KudosButton onClick={reset} type="button">
            I want to give another!
          </KudosButton>
        </ResultMessage>
      )}
      {state.error && (
        <ResultMessage>
          <h2>Something went horribly wrong!</h2>
          <KudosButton onClick={reset} type="Button">
            I want to try again!
          </KudosButton>
        </ResultMessage>
      )}
      {state.loading && !state.error && (
        <ResultMessage>
          <h2>Preparing to accept your Kudos</h2>
        </ResultMessage>
      )}
      {state.sending && (
        <ResultMessage>
          <h2>Your Kudos is on the way!</h2>
        </ResultMessage>
      )}
    </React.Fragment>
  );
};

export default Form;
