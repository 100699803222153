import styled from "styled-components";
import React, { useContext } from "react";
import { Backpack, Cat, IceCream, Planet } from "react-kawaii";
import { GlobalContext } from "./container";

const KawaiiAvatar = [Backpack, Cat, IceCream, Planet];

const Avatar = KawaiiAvatar[Math.floor(Math.random() * KawaiiAvatar.length)];

const Kawaii = styled(Avatar)`
  margin: 24px;
  grid-column: 2/3;
  grid-row: 2;
  justify-content: center;
`;

const KawaiiMoods = ["happy", "excited", "blissful", "lovestruck", "ko"];

const setMood = state => {
  if (state.error) {
    return KawaiiMoods[4];
  }

  if (state.success) {
    return KawaiiMoods[3];
  }

  if (state.posting) {
    return KawaiiMoods[3];
  }

  if (state.name.length && state.reason.length) {
    return KawaiiMoods[2];
  }

  if (state.name.length) {
    return KawaiiMoods[1];
  }

  return KawaiiMoods[0];
};

const InteractiveKawaii = props => {
  let { state } = useContext(GlobalContext);
  return <Kawaii aria-hidden="true" mood={setMood(state)} {...props} />;
};

export default InteractiveKawaii;
