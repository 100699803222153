import styled from "styled-components";
import { LiveArea } from "react-inclusive-components";

const ResultMessage = styled(LiveArea)`
  grid-column: 2/3;
  grid-row: 4;

  h2 {
    font-weight: 200;
  }
`;

export default ResultMessage;
